import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { map } from "lodash";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Progress,
    Row,
    Table,
} from "reactstrap";

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget, Img } from "./CryptoCol";

import TableContainer from "../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";
import profile2 from "assets/images/profile-img1.png";
import img3 from "assets/images/small/img-3.jpg";

// import charts
import ApexRevenue from "./ApexRevenue";
import {
    getUserById as onGetUser,
} from "../../store/user/actions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { Date, OrderId, PaymentStatus } from "pages/Subscription/SubscriptionCol";
import { getImage } from "helpers/common_helper";
import SubscriptionModal from "pages/Subscription/SubscriptionModel";

const UserDetails = props => {

    //meta title
    document.title = "Best Kids (Story Telling App) - User Profile";
    const [user, setUser] = useState({});
    const dispatch = useDispatch();
    const [modal1, setModal1] = useState(false);
    const [subscriber, setSubscriber] = useState(null);
    const { id } = useParams();

    const selectUserState = (state) => state.users;
    const userProperties = createSelector(
        selectUserState,
        (User) => ({
            users: User.user,
            loading: User.loading
        })
    );
    const { users, loading } = useSelector(userProperties);
    const [isLoading, setLoading] = useState(false)
    const [miniCards, setMiniCards] = useState([
        {
            title: "Finished Stories",
            iconClass: "bx-check-circle",
            text: "0",
        },
        { title: "Learning Hours", iconClass: "bx-hourglass", text: "0Hrs" },
        { title: "Achieved Skills", iconClass: "bx-package", text: "0" },
    ]);
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        if (id) {
            dispatch(onGetUser(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (users) {
            setUser(users);
            setMiniCards([
                {
                    title: "Finished Stories",
                    iconClass: "bx-check-circle",
                    text: users?.FinishedStories?.toString(),
                },
                { title: "Learning Hours", iconClass: "bx-hourglass", text: users?.LearningHours?.toString() + "Hrs" },
                { title: "Achieved Skills", iconClass: "bx-package", text: users?.AchievedSkills?.toString() }
            ])

        }

    }, [users]);

    const toggleViewModal = (subscriber) => {
        setModal1(!modal1);
        setSubscriber(subscriber)
    };


    const userProfile = {
        name: "Suresh K",
        img: profile2,
        phone: "+91 88888 00000",
        email: "sureshk.2023@gmail.com",
        location: "India",
        Profiles: [
            {
                id: 12,
                name: "Child Name I",
                age: 12,
                coverImage: img3,
                lastaccess: "Oct 08, 2023 08:12 PM"
            },
            {
                id: 12,
                name: "Child Name II",
                age: 8,
                coverImage: img3,
                lastaccess: "Oct 08, 2023 08:12 PM"
            },
        ],
        projects: [
            {
                id: 12,
                name: "The Velveteen Rabbit",
                chapter: "Chapter I",
                completed: 25,
                coverImage: img3,
                lastaccess: "Oct 08, 2023 08:12 PM"
            },
            {
                id: 13,
                name: "The Three Little Pigs",
                chapter: "Chapter II",
                completed: 80,
                coverImage: img3,
                lastaccess: "Oct 09, 2023 09:02 AM"
            }
        ],
        experiences: [
            {
                id: "1",
                designation: "Communication",
                timeDuration: "Oct 25, 2023",
                iconClass: "bx-play-circle"
            },
            {
                id: "2",
                designation: "Making Connections",
                timeDuration: "Oct 16, 2023",
                iconClass: "bx-hourglass"
            },
            {
                id: "2",
                designation: "Taking on Challenges",
                timeDuration: "Oct 08, 2023",
                iconClass: "bx-trophy"
            }
        ]
    }

    const subscribesColumn = useMemo(
        () => [

            {
                Header: "Order ID",
                accessor: "orderId",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    return <OrderId {...cellProps} />;
                },
            },
            {
                Header: "Plan",
                accessor: "paymentMethod",
                disableFilters: true,
                Cell: cellProps => {
                    return (
                        cellProps.row.original.subscription.name + " (" + cellProps.row.original.subscription.duration + " Month's)"
                    )
                }
            },

            {
                Header: "Price",
                accessor: "price",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return (
                        cellProps.row.original.subscription.price?.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                        })
                    )
                },
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <PaymentStatus {...cellProps} />;
                },
            },
            {
                Header: "Date",
                accessor: "orderDate",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <Date {...cellProps} />;
                },
            },

            {
                Header: "View Details",
                disableFilters: true,
                accessor: "view",
                Cell: cellProps => {
                    return (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => toggleViewModal(cellProps.row.original)}
                        >
                            View Details
                        </Button>
                    );
                },
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                Header: "Story",
                accessor: "name",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div>
                            <h5 className="text-truncate font-size-14">
                                <Link
                                    to={`/projects-overview/${cellProps.row.original.id}`}
                                    className="text-dark"
                                >
                                    {cellProps.row.original.story.name}
                                </Link>
                            </h5>
                            <p className="text-muted mb-0">
                                {cellProps.row.original.chapter.name}
                            </p>
                        </div>
                    )
                },
            },
            {
                Header: "Completed",
                accessor: "completed",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return (
                        <Progress
                            value={GetCompletion(cellProps.row.original.timeInSec, cellProps.row.original.timeInSecTotal)}
                            color="primary"
                        ></Progress>
                    )
                },
            },
            {
                Header: "Last Access",
                accessor: "updatedAt",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <Ddate {...cellProps} />;
                },
            },
        ],
        []
    );

    const GetCompletion = (watchingTime, totalTime)=>{
        if(!totalTime)
            totalTime = 120;
        return (watchingTime*100)/totalTime;
    }

    const columnsFav = useMemo(
        () => [
            {
                Header: "Story",
                accessor: "name",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div>
                            <h5 className="text-truncate font-size-14">
                                <Link
                                    to={`/projects-overview/${cellProps.row.original.id}`}
                                    className="text-dark"
                                >
                                    {cellProps.row.original.story.name}
                                </Link>
                            </h5>
                            <p className="text-muted mb-0">
                                {cellProps.row.original.chapter.name}
                            </p>
                        </div>
                    )
                },
            },
        ],
        []
    );

    const columnsProfile = useMemo(
        () => [
            {
                Header: "#",
                accessor: "coverImage",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return <Img {...cellProps} />;
                },
            },
            {
                Header: "Profile Name",
                accessor: "name",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div>
                            <h5 className="text-truncate font-size-14">
                                <Link
                                    to={`/projects-overview/${cellProps.row.original.id}`}
                                    className="text-dark"
                                >
                                    {cellProps.row.original.name}
                                </Link>
                            </h5>
                            <p className="text-muted mb-0">
                                {cellProps.row.original.chapter}
                            </p>
                        </div>
                    )
                },
            },
            {
                Header: "Gender",
                accessor: "gender",
                disableFilters: true,
                filterable: true,

            },
            {
                Header: "Age (Yrs)",
                accessor: "age",
                disableFilters: true,
                filterable: true,

            },
            // {
            //     Header: "Last Access",
            //     accessor: "lastaccess",
            //     disableFilters: true,
            //     filterable: true,
            //     Cell: cellProps => {
            //         return <Ddate {...cellProps} />;
            //     },
            // },
        ],
        []
    );

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <SubscriptionModal subscriber={subscriber} isOpen={modal1} toggle={toggleViewModal} />
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

                        <Row>
                            <Col xl="4">
                                <Card className="overflow-hidden">
                                    <div className="bg-primary-subtle">
                                        <Row>
                                            <Col xs="7">
                                                <div className="text-primary p-3">
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <h5 className="font-size-14 text-truncate">
                                                        {user?.Profile?.name}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xs="5" className="align-self-end">
                                                <img src={profile1} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col sm="4">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <img
                                                        src={(user?.Profile)?getImage(user?.Profile?.picture.path):""}
                                                        alt=""
                                                        className="img-thumbnail rounded-circle"
                                                    />
                                                </div>
                                            </Col>

                                            <Col sm={8}>
                                                <div className="pt-4">
                                                    <Row>
                                                        <Col xs="4">
                                                            <h5 className="font-size-14 badge badge-soft-success">
                                                                {user?.UserProfiles?.length}
                                                            </h5>
                                                            <p className="text-muted mb-0">Profiles</p>
                                                        </Col>
                                                        <Col xs="8">
                                                            <h5 className="font-size-14 badge badge-soft-info">
                                                                {user?.CurrentPlan}
                                                            </h5>
                                                            <p className="text-muted mb-0">Current Plan</p>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Personal Information</CardTitle>
                                        <p className="text-muted mb-4">
                                            {userProfile.personalDetail}
                                        </p>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Full Name :</th>
                                                        <td>{user?.Profile?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Mobile :</th>
                                                        <td>{(user?.Profile?.mobile) ? user?.Profile?.mobile?.number : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">E-mail :</th>
                                                        <td>{(user?.Profile?.email) ? user?.Profile?.email : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Location :</th>
                                                        <td>{(user?.Profile?.location) ? user?.Profile?.location : "N/A"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-5">Achieved Skills </CardTitle>
                                        <div >
                                            <ul className="verti-timeline list-unstyled">
                                                {map(userProfile.experiences, (experience, i) => (
                                                    <li
                                                        className={
                                                            experience.id === 1
                                                                ? "event-list active"
                                                                : "event-list"
                                                        }
                                                        key={"_exp_" + i}
                                                    >
                                                        <div className="event-timeline-dot">
                                                            <i
                                                                className={
                                                                    experience.id === 1
                                                                        ? "bx bx-right-arrow-circle bx-fade-right"
                                                                        : "bx bx-right-arrow-circle"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex">

                                                            <div className="flex-grow-1">
                                                                <h5 className="font-size-15 badge badge-soft-primary">
                                                                    {experience.designation}
                                                                </h5>
                                                                <div className="text-dark">
                                                                    {experience.timeDuration}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="d-flex">Favourite Stories <h3><i className="bx bxs-heart text-danger"></i></h3></CardTitle>
                                        <TableContainer
                                            columns={columnsFav}
                                            hideColumns={true}
                                            data={user?.favorites || []}
                                            isGlobalFilter={false}
                                            customPageSize={5}
                                            customPageSizeOptions={true}
                                        />
                                    </CardBody>
                                </Card>
                                
                            </Col>

                            <Col xl="8">
                                <Row>
                                    {map(miniCards, (card, key) => (
                                        <MiniCards
                                            title={card.title}
                                            text={card.text}
                                            iconClass={card.iconClass}
                                            key={"_card_" + key}
                                        />
                                    ))}
                                </Row>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Spending Time</CardTitle>
                                        <div id="revenue-chart">
                                            <ApexRevenue myData={user.SpendingTime} dataColors='["--bs-primary"]' />
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Profiles</CardTitle>

                                        <TableContainer
                                            columns={columnsProfile}
                                            data={user?.UserProfiles || []}
                                            isGlobalFilter={false}
                                            customPageSize={5}
                                            customPageSizeOptions={true}
                                        />
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Stories Statistics</CardTitle>

                                        <TableContainer
                                            columns={columns}
                                            data={(users?.resumes)?users?.resumes:[]}
                                            isGlobalFilter={false}
                                            customPageSize={5}
                                            customPageSizeOptions={true}
                                        />
                                    </CardBody>
                                </Card>
                                <Card >
                                    <CardBody>
                                        <CardTitle className="mb-2">Subscription Details</CardTitle>
                                        <TableContainer
                                            columns={subscribesColumn}
                                            data={user?.Subscribes || []}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            isPagination={true}
                                            iscustomPageSizeOptions={false}
                                            customPageSize={10}
                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                        />
                                    </CardBody>
                                </Card>
                                
                            </Col>
                            <Col sm={12}>
                            
                            </Col>
                            <Col sm={6}>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
    );
};
export default withRouter(UserDetails);
