import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { StatisticsApplicationsChart } from './StatisticCharts';

const Statistics = () => {
    const [duration, setDuration] = useState('year');
    const [statisData, setStaticData] = useState(
        {
            subscriptions: [
                30, 20, 30, 45, 66, 25
            ],
            revenue: [
                20, 2, 35, 89, 102, 20
            ],

            label: [
                "2023",
                "2022",
                "2021",
                "2019",
                "2018",
                "2017",
            ]
        }
    )


    const changeDuration = (duration) => {
        setDuration(duration)
        if (duration == "month") {
            setStaticData(
                {
                    subscriptions: [
                        12, 36, 56, 77, 18, 3
                    ],
                    revenue: [
                        38, 22, 30, 66, 10, 23
                    ],
        
                    label: [
                        "Jan 2023",
                        "Feb 2023",
                        "Mar 2023",
                        "Apr 2023",
                        "Aug 2023",
                        "Sep 2023",
                    ]
                }
            )
        }else if(duration == "year"){
            setStaticData({
                subscriptions: [
                    30, 20, 30, 45, 66, 25
                ],
                revenue: [
                    20, 2, 35, 89, 102, 20
                ],
    
                label: [
                    "2023",
                    "2022",
                    "2021",
                    "2019",
                    "2018",
                    "2017",
                ]
            })
        }
    };

    return (
        <React.Fragment>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <div className="d-sm-flex flex-wrap">
                            <h4 className="card-title mb-4">Statistics</h4>
                            <div className="ms-auto">
                                <Nav pills>
                                    {/* <NavItem>
                                        <NavLink className={duration === 'week' ? 'active' : ''} href="#" onClick={() => changeDuration('week')}>Week</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink className={duration === 'month' ? 'active' : ''} href="#" onClick={() => changeDuration('month')}>Month</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={duration === 'year' ? 'active' : ''} href="#" onClick={() => changeDuration('year')}>Year</NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                        <StatisticsApplicationsChart seriesData={statisData} dataColors='["--bs-primary", "--bs-success"]' dir="ltr" />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Statistics;