import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import TableContainer from '../../components/Common/TableContainer'

// Column
import {
  UserName,
  Address,
  Rating,
  WalletBalances,
  JoiningDate,
} from './UsersCol';
import moment from "moment";
import { PatternFormat } from "react-number-format";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { CustomBadge } from "pages/Subscription/SubscriptionCol";
import { Link } from "react-router-dom";
import {
  getUser as onGetUser,
} from "../../store/user/actions";
import { getImage } from "helpers/common_helper";

const Users = () => {

  //meta title
  document.title = "Best Kids (Story Telling App) - Users";

  const dispatch = useDispatch();
  
  const selectUserState = (state) => state.users;
  
  const userProperties = createSelector(
    selectUserState,
    (User) => ({
      users: User.users,
      loading: User.loading
    })
  );
  const { users, loading } = useSelector(userProperties);
  const [isLoading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUser());
    }
  }, [dispatch]);
  
  const _customer = [
    {
      username: "testuser2023",
      phone: "+91 00000 00000",
      email: "test@gmail.com",
      plans: "Free",
      address: "India",
      createdBy: "Admin",
      category: "Bed Time",
      joiningDate: "Oct 18, 2023"
    },
    {
      username: "suresh1989",
      phone: "+91 88888 88888",
      email: "sureshv@gmail.com",
      plans: "VIP",
      address: "India",
      createdBy: "Admin",
      category: "Bed Time",
      joiningDate: "Oct 18, 2023"
    },
    {
      username: "balaks2018",
      phone: "+91 88888 88888",
      email: "balasrinivas@gmail.com",
      plans: "Premium",
      address: "India",
      createdBy: "Admin",
      category: "Bed Time",
      joiningDate: "Oct 18, 2023"
    },

  ];

  const handleCustomerClick = arg => {
    const customer = arg;

    setCustomer({
      id: customer.id,
      username: customer.username,
      phone: customer.phone,
      email: customer.email,
      address: customer.address,
      rating: customer.rating,
      walletBalance: customer.walletBalance,
      joiningDate: customer.joiningDate,
    });

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps?.picture ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={getImage(cellProps.picture.path)}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
      {
        Header: 'Username',
        accessor: 'name',
        filterable: true,
        Cell: (cellProps) => {
          return <UserName {...cellProps} />;
        }
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        filterable: true,
        Cell: (cellProps) => {
          return <>
            <p className="mb-1">{(cellProps.row.original.mobile)?cellProps.row.original?.mobile?.number:"N/A"}</p>
          </>;
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        Cell: (cellProps) => {
          return <>
            <p className="mb-0">{(cellProps.row.original.email)?cellProps.row.original?.email:"N/A"}</p>
          </>;
        }
      },
      // {
      //   Header: 'Current Plan',
      //   accessor: 'plans',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <CustomBadge color="info" {...cellProps} />;
      //   }
      // },
      {
        Header: 'Joining Date',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return (moment(cellProps.row.original.createdAt).format('MMM D, YYYY'));
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link to={"/user-details/" + cellProps.row.original._id} className="btn btn-soft-primary" id={`viewtooltip-${cellProps.row.original.id}`}>
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target={`viewtooltip-${cellProps.row.original.id}`}>
                View
              </UncontrolledTooltip>

              {/* <li>
                <Link
                  to="#"
                  className="btn btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original;
                    onClickDelete(jobData);
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
            </ul>
          );
        }
      },
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (customer) => {
    setCustomer(customer);
    setDeleteModal(true);
  };


  const handleCustomerClicks = () => {
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Users" />
          <Row>
            {
              loading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={users}
                        isGlobalFilter={true}
                        isAddCustList={false}
                        isPagination={true}
                        handleCustomerClick={handleCustomerClicks}
                        customPageSize={8}
                        tableClass="align-middle table-nowrap"
                        isShowingPageLength={false}
                        iscustomPageSizeOptions={true}
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

Users.propTypes = {
  users: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
};

export default Users;
