import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";
import categorySaga from "./category/saga";
import tagSaga from "./tag/saga";
import coupanSaga from "./coupan/saga";
import subscriptionSaga from "./subscription/saga";
import storySaga from "./story/saga";
import chapterSaga from "./chapter/saga";
import slideSaga from "./slides/saga";
import homeSaga from "./home/saga";
import notificatonSaga from "./notification/saga";
import subscriberSaga from "./subscriber/saga";
import userSaga from "./user/saga";
import dashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(tagSaga),
    fork(categorySaga),
    fork(coupanSaga),
    fork(subscriptionSaga),
    fork(storySaga),
    fork(chapterSaga),
    fork(slideSaga),
    fork(homeSaga),
    fork(notificatonSaga),
    fork(subscriberSaga),
    fork(userSaga),
    fork(dashboardSaga)
  ]);
}
