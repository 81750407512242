import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    CardTitle,
    TabPane,
    Button
} from "reactstrap"

import classnames from "classnames"

import Breadcrumbs from "../../components/Common/Breadcrumb";
import CreateStory from "./components/CreateStory";
import CreateChapters from "./components/CreateChapters";
import { useParams } from 'react-router-dom';
import { createSelector } from "reselect";
import {
    getStory as onGetStory,
    getStorys as onGetStories,
} from "../../store/story/actions";
import { useSelector, useDispatch } from "react-redux";
import Spinners from "components/Common/Spinner";


const CreateAlbum = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    
    document.title = "Best Kids (Story Telling App) - Create Story";
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [myStory, setMyStory] = useState({});
    const selectCategoryState = (state) => state.storys;
    const CategoryProperties = createSelector(
        selectCategoryState,
        (Story) => ({
            story: Story.story,
            loading: Story.loading
        })
    );

    const {story, loading} = useSelector(CategoryProperties);
    
    useEffect(() => {
        // if (id) {
            dispatch(onGetStory(id));
            // dispatch(onGetStories())
        // }
    }, [dispatch, id]);

    useEffect(() => {
        if (Object.keys(story).length != 0) {
            setIsEdit(true)
            setPassedSteps(true)
            setMyStory(story)
        } else {
            setIsEdit(false)
            setPassedSteps(false)
            setMyStory({})
            setactiveTab(1)
        }
        
    }, [story]);

    const [isLoading, setLoading] = useState(loading)
    
    return (
        <React.Fragment>
            {loading ? <Spinners setLoading={setLoading} />
                                :
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Album" breadcrumbItem="Create Story" />
                    <div className="wizard clearfix">
                        <div className="steps clearfix">
                            <ul>
                                <NavItem
                                    className={classnames({ current: activeTab === 1 })}
                                >
                                    <NavLink
                                        className={classnames({ current: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1)
                                        }}
                                    >
                                        <span className="number">1.</span> Story Details
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    className={classnames({ current: activeTab === 2 })}
                                >
                                    <NavLink
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2)
                                        }}
                                        disabled={!passedSteps}
                                    >
                                        <span className="number">2.</span> Add Chapters
                                    </NavLink>
                                </NavItem>

                            </ul>
                        </div>
                        <div className="content clearfix">
                            <TabContent activeTab={activeTab} className="body pt-0">
                                <TabPane tabId={1}>
                                    <CreateStory isEdit={isEdit} loading={loading} story={myStory} />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <CreateChapters story={myStory} />
                                </TabPane>

                            </TabContent>
                        </div>

                    </div>

                </Container>
            </div >
}
        </React.Fragment >
    )
}

export default CreateAlbum;
