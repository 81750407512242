import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getCoupans as onGetCoupans,
    addNewCoupan as onAddNewCoupan,
    updateCoupan as onUpdateCoupan,
    deleteCoupan as onDeleteCoupan,
} from "../store/coupan/actions";
import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import Select from "react-select";

const Coupans = props => {

    //meta title
    document.title = "Best Kids (Story Telling App) - Coupans";

    const dispatch = useDispatch();
    const [coupan, setCoupan] = useState();
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (coupan && coupan.name) || "",
            discount: (coupan && coupan.discount) || "",
            expiry: (coupan && coupan.expiry) || ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            discount: Yup.string().required("Please Enter Discount"),
            expiry: Yup.string(),
        }),
        onSubmit: values => {
            if (isEdit) {
                const updateCoupan = {
                    id: coupan.id,
                    name: values.name,
                    discount: values.discount,
                    expiry: expiryDate,
                };
                dispatch(onUpdateCoupan(updateCoupan));
                setIsEdit(false);
                validation.resetForm();
            } else {
                const newCoupan = {
                    name: values["name"],
                    discount: values["discount"],
                    expiry: expiryDate,
                };
                // save new user
                dispatch(onAddNewCoupan(newCoupan));
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectCoupansState = (state) => state.coupans;
    const CoupansProperties = createSelector(
        selectCoupansState,
        (Coupans) => ({
            coupans: Coupans.coupan,
            loading: Coupans.loading
        })
    );

    const {
        coupans, loading
    } = useSelector(CoupansProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [coupanList, setCoupanList] = useState([]);
    const options = [
        { value: "All", label: "All" },
        { value: "Test Album I", label: "Test Album I" },
        { value: "Test Album II", label: "Test Album II" },

    ]
    const [expiryDate, setexpiryDate] = useState(new Date())
    const expiryDateChange = date => {
        setexpiryDate(date[0])
    }
    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Discount (%)",
                accessor: "discount",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.discount}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Expiry Date",
                accessor: "expiry",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.expiry).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleCoupanClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (coupans && !coupans.length) {
            dispatch(onGetCoupans());
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setCoupan(coupans);
        setIsEdit(false);
    }, [coupans]);

    useEffect(() => {
        if (!isEmpty(coupans) && !!isEdit) {
            setCoupan(coupans);
            setIsEdit(false);
        }
    }, [coupans]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleCoupanClick = arg => {
        const user = arg;
        setCoupan({
            id: user.id,
            name: user.name,
            discount: user.discount,
        });
        setexpiryDate(user.expiry)
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = coupans => {
        setCoupan(coupans);
        setDeleteModal(true);
    };

    const handleDeleteCoupan = () => {
        if (coupan && coupan.id) {
            dispatch(onDeleteCoupan(coupan.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleCoupanClicks = () => {
        setCoupanList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteCoupan}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Coupans" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={coupans}
                                                isGlobalFilter={true}
                                                isShowingPageLength={true}
                                                isAddUserList={true}
                                                buttonText={"Create Coupan"}
                                                iscustomPageSizeOptions={true}
                                                handleUserClick={handleCoupanClicks}
                                                customPageSize={10}
                                                tableClass="table align-middle table-nowrap table-hover"
                                                theadClass="table-light"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination pagination-rounded justify-content-end mt-4"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit Coupan" : "Add Coupan"}
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Coupan Code</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name &&
                                                            validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name &&
                                                    validation.errors.name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Discount</Label>
                                                <Input
                                                    name="discount"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.discount || ""}
                                                    invalid={
                                                        validation.touched.discount &&
                                                            validation.errors.discount
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.discount &&
                                                    validation.errors.discount ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.discount}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            {/* <div className="mb-3">
                                                <Label>Select Albums</Label>

                                                <Select
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Choose..."
                                                    title="Country"
                                                    options={options}
                                                    isMulti
                                                />
                                                {validation.touched.duration && validation.errors.duration ? (
                                                    <FormFeedback type="invalid" className="d-block">{validation.errors.duration}</FormFeedback>
                                                ) : null}
                                            </div> */}
                                            <div className="mb-3">
                                                <Label>Expires In</Label>
                                                <FlatPickr
                                                    className="form-control"
                                                    name="expiry"
                                                    options={{
                                                        dateFormat: "d M,Y",
                                                        defaultDate: expiryDate
                                                    }}
                                                    placeholder="Select Date"
                                                    selected={expiryDate}
                                                    onChange={expiryDateChange}
                                                />
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(Coupans);
