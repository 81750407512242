import React, { useEffect, useState, useRef, useMemo } from "react";
import { getCategoryCombo } from "../../store/category/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const CategorySelect = ({ OnSelect, defaultValue, error, isSubmitted  }) => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [touchedCategory, setTouchedCategory] = useState(false);

    const selectContactsState = (state) => state.categories;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Category) => ({
            categories: Category.categoryCombo,
            loading: Category.loading
        })
    );
    const { categories, loading } = useSelector(ContactsProperties);

    const handleSelect = (val) => {
        setSelectedCategory(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    useEffect(() => {
        if (categories && !categories.length) {
            dispatch(getCategoryCombo());
        }
        setTouchedCategory(false);
    }, [dispatch, categories]);

    useEffect(() => {
        if (categories && categories.length != 0 && defaultValue) {
            const defaultCategory = categories.find((option) => option.value === defaultValue);

            if (defaultCategory) {
                setSelectedCategory(defaultCategory);
            }
        }
        if (!defaultValue) {
            setSelectedCategory(null);
        }
    }, [defaultValue, categories]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={categories}
                value={selectedCategory}
                onChange={(val) => handleSelect(val)}
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default CategorySelect