import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../components/Common/ChartsDynamicColor";


const StatisticsApplicationsChart = ({ seriesData, dataColors }) => {
    var statisticsApplicationColors = getChartColorsArray(dataColors);

    const series = [{
        name: 'Total Subscriptions',
        data: (seriesData.subscriptions || [])
    }, {
        name: 'Total Revenue',
        data: (seriesData.revenue || [])
    }];
    var options = {
        chart: {
            height: 350,
            type: "area",
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        xaxis: {
            categories: (seriesData.label || []),
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100],
            },
        },

        colors: statisticsApplicationColors,
        markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
                size: 4,
                sizeOffset: 2,
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
                className="apex-charts"
            />
        </React.Fragment>
    );
};


export { StatisticsApplicationsChart };